@import '../../utilities/variables';

.trip-divider {
  border: 1px solid $secondary-yellow;
  border-radius: 4px;
  margin: 16px 0;
}

.trip-details-row {
  display: flex;
  justify-content: space-between;
}

.trip-text {
}

.trip-bold {
  font-weight: bold;
}
