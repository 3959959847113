@import '../../utilities/variables';

.trips-page-toggle-buttons {
  display: flex;
  flex-direction: row;
  padding-bottom: 4px;

  @media (max-width: $phone-width) {
    flex-direction: column;
  }

  .fremtur-btn {
    &.toggle {
      border: none;
      background: none;
      margin: 0;
      padding: 0 1rem;
      @media (max-width: $phone-width) {
        flex-direction: column;
        align-items: center;
        &:first-child {
          padding-bottom: 8px;
        }
      }


      &.active {
        text-decoration: underline 3px;
        text-underline-style: thick;
        font-weight: bold;

      }
    }
  }
}
