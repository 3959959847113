@import '../../utilities/variables.scss';

.passenger-rating {
  display: flex;
  flex-direction: column;

  .divider {
    border: 1px solid $secondary-yellow;
    border-radius: 4px;
    margin: 16px 0;
  }

  @media (max-width: $phone-width) {
    font-size: small;
  }

  .rating-distribution-item {
    display: flex;
    justify-content: space-between;
  }

  .ratings {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .rating {
      background-color: $tertiary-yellow;
      border: 1px solid $primary-yellow;
      border-radius: 10px;
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .gray {
      color: $secondary-grey
    }
  }
}
