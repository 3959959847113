@import '../../utilities/variables';

.vehicle-list {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .vehicle-info-card {
    background: $tertiary-yellow;
    border: 1px solid $secondary-yellow;
    padding: 15px;
    border-radius: 4px;
    max-width: 90%;
    width: $card-width;

    @media (max-width: $phone-width) {
      width: $card-width-phone;
    }

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    .vehicle-info-row {
      padding: 5px;
      display: flex;
      justify-content: space-between;

      .vehicle-info-col {
        display: flex;
        align-items: center;

        .vehicle-info-icon {
          margin-right: 10px;
          color: $secondary-grey;
        }

        .vehicle-info-label {
          color: $secondary-grey;
          margin-right: 5px;
        }

        .vehicle-info {
          display: flex;

          .vehicle-info-data {
            font-weight: bold;
          }

          .vehicle-form-input {
            padding: 5px;
            border: 1px solid rgba(142, 142, 142, 0.5);
            font: inherit;
            border-radius: 4px;
            margin-right: 2px;

            &.color {
              width: 20%;
            }

            &.brand {
              width: 80%;
            }
          }
        }

        .vehicle-floating {
          text-align: center;
          padding: .25rem .5rem;
          margin-left: .5rem;
          border-radius: 4px;
          transition: background-color 0.3s ease;
          cursor: pointer;
          box-shadow: .5px .5px .5px #999;

          &:active {
            transform: scale(0.85);
          }

          &.save {
            background-color: $approved;
            border: 0;

            &:hover {
              background-color: $approved-dark;
            }
          }
        }
      }

    }
  }
}