@import '../../utilities/variables';

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;

  .modal {
    background: $tertiary-yellow;
    padding: 20px;
    border-radius: 10px;
    width: 25rem;
    max-width: $card-width;
    margin: 0 1rem;
    border: 1px solid $secondary-yellow;

    .modal-title {
      margin-top: 0;
    }

    .modal-actions {
      display: flex;
      justify-content: space-around;

      .fremtur-btn{
        margin: 0 5px !important;
      }


      .cancel-button {
        color: $primary-black;
        background-color: $primary-grey;
        margin: 0;
      }

      .approve-button {
        color: $primary-black;
        background-color: $approved;
        margin: 0
      }

      .reject-button {
        color: $primary-black;
        background-color: $rejected;
        margin: 0
      }

      .confirm-button {
        color: $primary-black;
        margin: 0
      }

    }
  }
}