@import '../../utilities/variables';

footer {
  display: flex;
  justify-content: space-between;

  padding: 1rem;
  margin-top: 16px;
  flex-shrink: 0;
  background-color: $secondary-yellow;
  align-items: center;

  @media (max-width: $phone-width) {
    padding: 0;
  }

  .footer-info {
    margin-left: 2rem;

    a {
      color: $primary-black;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    @media (max-width: $phone-width) {
      padding: 1rem;
      margin: 0;
    }
  }

  .social-links {
    margin-right: 2rem;
    display: flex;
    @media (max-width: $phone-width) {
      margin: 0;
    }

    a {
      margin: 0 10px;
      color: $primary-black;
      transition: color 0.3s ease;

      &:hover {
        color: $primary-yellow;
      }

      i {
        font-size: 1.5rem;
      }
    }
  }
}

