@import '../../utilities/variables';

.search-form {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 32px;

  .form-error {
    color: $primary-red;
    font-weight: bold;
    margin: 0;
    font-size: medium;
  }

  @media (max-width: $pad-width) {
    flex-direction: column;
    align-items: center;
  }

  .search-btn {
    margin: 0;
  }
}

.form-input {
  padding: 17.5px;
  border: 1px solid $primary-grey;
  font: inherit;
  border-radius: 4px;

  &:hover {
    border-color: $primary-black;
  }

}

.form-input-date {
  width: 8rem;
}

