@import '../../utilities/variables';


.trip-rating {

  .trip-rating-row {
    display: flex;
    justify-content: space-between;

    .trip-rating-btn {
      margin: 0
    }

    .trip-stars-row {
      display: flex;
      align-items: center;
      font-size: small;


      .trip-driver-icon {
        margin-right: 4px;
      }

      .trip-driver {
        margin-right: 0.5rem;
      }


      .trip-star {
        cursor: pointer;
        font-size: 1.5rem;
        padding: .1rem;

        &.marked {
          color: $primary-yellow;
        }

        &.unmarked {
          color: $primary-grey;
        }
      }
    }

    .trip-driver-rating {
      font-size: small;
      background-color: $secondary-yellow;
      padding: 4px;
      border-radius: 4px;
    }
  }
}
