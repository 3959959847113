@import 'utilities/variables';

.fremtur-btn {
  color: $primary-black;
  border: 1px solid $primary-black;
  background-color: $primary-yellow;
  padding: 17.5px;
  font: inherit;
  border-radius: 4px;
  cursor: pointer;
  margin: 1rem;

  &:hover {
    border-color: $primary-black;
    filter: brightness(1.1);
  }

  &.small {
    padding: 10px;
  }

  &.increment {
    border-radius: 100rem;
    height: 3rem;
    width: 3rem;
    border: 0;
    padding: 0;
  }

  &:disabled {
    background-color: $primary-yellow;
    cursor: not-allowed;
    opacity: 0.35;
  }

  &:active {
    transform: scale(0.95);
  }
}

.fremtur-heading {
  font-family: "Lora Variable", system-ui, sans-serif;
  text-align: center;

  @media (max-width: 48rem) {
    font-size: x-large;
  }
}

.fremtur-paragraph {
  margin: 1.5rem 0;
}

.heading-nb {
  padding: 1rem;
  margin: 1rem;
  background-color: $primary-yellow;
  font-weight: bold;
  color: $primary-black;
  border-radius: .2rem;
  max-width: 90%;
}


.error-message {
  color: red;
  margin: 20px 0;
  text-align: center;
}

.container-fremtur {
  flex: 1;

  .container-top {
    background-color: $primary-yellow;

    @media (max-width: 48rem) {
      padding: 2rem 2rem;
    }
  }
}

.container-center {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker-popper {
  z-index: $z-index-datepicker;
}

.fremtur-form {
  width: 20rem;
  display: flex;
  flex-direction: column;

  .form-error {
    color: $primary-red;
    font-weight: bold;
    margin: 0;
    font-size: medium;
  }

  .radio-group {
    margin: 1rem 0;

    .radio-option {
      display: flex;
      align-items: center;
      padding: .5rem 0;
      font-size: large;
      cursor: pointer;

      label, input {
        cursor: pointer;
      }

      &:hover {
        font-weight: bold;
      }

      input[type="radio"] {
        accent-color: $primary-yellow;
        margin-right: 1rem;
      }

      label {
        margin: 0;
      }
    }
  }
}

.edit-icon {
  color: black;
  background-color: $edit;
  box-shadow: .5px .5px .5px #999;
  padding: .25rem .5rem;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: $edit-dark;
  }
}

.cancel-icon {
  background-color: $cancel;

  color: black;
  box-shadow: .5px .5px .5px #999;
  padding: .25rem .5rem;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: $cancel-dark;
  }

  &.margin-left {
    margin-left: 8px;
  }
}

.fremtur-link {
  color: black;
  text-decoration: none;
}