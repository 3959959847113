// Colors
$primary-black: #000000;
$primary-white: white;
$primary-red: #ff0000;
$primary-grey: rgba(142, 142, 142, 0.50);
$secondary-grey: #6a6969;
$tertiary-grey: #ececec;
$primary-yellow: #f9c767;
$secondary-yellow: #ffe5b2;
$tertiary-yellow: #fffbee;
$vipps-orange: #ff5b24;
$text-grey: #999;

// Width
$card-width: 31rem;
$card-width-phone: 20rem;
$chat-width: 25rem;
$create-trip-card-width: 40rem;
$content-width: 40rem;
$pad-width: 768px;
$phone-width: 480px;

// Height
$bar-height: 4px;
$bar-width: 30px;

// Status
$pending: #c0dae5;
$pending-dark: rgba(192, 218, 229, 0.5);
$approved: #c0e6d5;
$approved-dark: rgba(192, 230, 213, 0.5);
$canceled: #ea9c8c;
$rejected: #ea9c8c;
$cancel: #e6765f;
$cancel-dark: rgba(230, 118, 95, 0.5);
$edit: #f9c767;
$edit-dark: rgba(249, 199, 103, 0.5);
$warning: #c75656;

// Z-indices
$z-index-header: 10;
$z-index-datepicker: 20;
