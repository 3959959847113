
.vehicle-form {
  display: flex;
  flex-direction: column;
  align-items: center;

  .vehicle-form-group {
    display: flex;
    flex-direction: column;

    .form-input {
      margin: 0;
    }
  }
}