@import '../../utilities/variables';

.chat {
  display: flex;
  flex-direction: column;
  max-height: 100%;

  .chat-header {
    padding: 1.2rem;
    font-size: large;
    font-weight: bold;
  }

  .chat-empty {
    padding: 0 1.2rem;
  }

  .chat-list {
    overflow-y: auto;
    max-height: 100%;
    list-style: none;
    padding: 0;

    .chat-list-row {
      @media (max-width: $phone-width) {
        display: flex;
        flex-direction: column;
      }

      .chat-bubble {
        width: $chat-width;
        cursor: pointer;
        padding: 1rem;
        margin: 0 1rem 1rem 1rem;
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: left;
        border: 0;
        font: inherit;
        color: inherit;
        background-color: $primary-white;

        @media (max-width: $phone-width) {
          width: inherit;
        }

        &:hover {
          background-color: $tertiary-grey;
        }

        &.active {
          background-color: $tertiary-grey;
        }

        .chat-icon {
          font-size: xxx-large;
          margin-right: 1rem;
        }

        .chat-info {
          display: flex;
          flex-direction: column;

          i {
            color: green;
          }

          .chat-name {
          }

          .chat-reservation-details {
            font-size: small;
          }
        }
      }
    }
  }
}
