@import '../../utilities/variables';


.about-container {
  flex-direction: row;

  .fremtur-info {
    display: flex;
    padding: 0 1rem;

    .fremtur-text {
      max-width: 40rem;
    }

    .fremtur-icon {
      max-width: 20rem;

      .container-icon {
        color: $primary-yellow;
        font-size: 10rem;
        text-align: right;
      }

    }
  }
}