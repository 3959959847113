@import '../../utilities/variables';

.chat-message {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: 100%;

  .chat-message-list {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 20px;
    flex-grow: 1;
    max-height: 100%;
    overflow-y: auto;

    .chat-message-item {
      display: flex;
      padding: .75rem;
      align-self: flex-start;
      margin: 5px 75px 0 0;
      border-radius: 10px 10px 10px 0;
      background-color: $tertiary-grey;

      &.own {
        margin: 5px 0 0 75px;
        border-radius: 10px 10px 0 10px;
        align-self: flex-end;
        justify-content: flex-end;
        background-color: $primary-yellow;
        align-items: flex-end;
      }

      .chat-message-bubble {
        word-break: break-word;
        white-space: break-spaces;
      }
    }
  }

  .chat-message-header {
    display: flex;
    justify-content: space-between;

    .chat-message-sender {
      padding: 1.2rem;
      font-size: large;
      font-weight: bold;
    }

    .chat-message-back {
      .chat-message-back-button {
        border: 0;
        color: $primary-black;
        background-color: $primary-white;
        padding: 1.2rem;
        font-size: large;
        cursor: pointer;
      }
    }
  }

  .chat-message-divider {
    border: 1px solid $secondary-grey;
    border-radius: 5rem;
    margin: 0 1rem;
  }
}
