@import '../../utilities/variables';

.chat-message-form {
  padding: 1.25rem;
  display: flex;

  .chat-message-input {
    padding: .75rem;
    border-radius: 10px;
    border: 1px solid #ccc;
    width: 100%;
    resize: none;
    field-sizing: content;
    word-break: break-word;
  }

  .send-button {
    height: 3.05rem;
    width: 3.2rem;
    margin-left: .5rem;
    padding: .95rem 1.05rem;
    border-radius: 5rem;
    background-color: $primary-white;
    border: 2px solid $primary-grey;

    align-self: end;

    &:hover {
      background-color: $tertiary-grey;
      cursor: pointer;
    }

    &:active {
      transform: scale(0.95);
    }

    &:focus {
      outline: 0;
    }

    .send-icon {
      color: $primary-black
    }
  }
}
