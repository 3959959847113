@import '../../utilities/variables';

.trip-user-info-row {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;

  .user-info-label {
    margin-right: 8px;
  }

  .user-info-data {
    color: $text-grey;

    .user-info-icon {

    }

  }

  .trip-user-rating {
    font-size: small;
    background-color: $secondary-yellow;
    padding: 4px;
    border-radius: 4px;
  }


}