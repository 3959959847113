@import '../../utilities/variables';

.chat-container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow: hidden;

  .chat-list-container {
    @media (max-width: $phone-width) {
      width: 100%;
      &.show-messages {
        display: none;
      }
    }
  }

  .chat-message-list-container {
    display: flex;
    width: 100%;
    @media (max-width: $phone-width) {
      display: none;
      &.show-messages {
        display: flex;
      }
    }
  }
}
