@import '../../utilities/variables';


.trip-form {
  .form-card {
    .form-row-single {
      justify-content: center !important;
    }

    .form-row-triple {
      .form-input {
        width: 8rem !important;
      }
    }

    .form-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 0 1rem;
      gap: 0.5rem;

      @media (max-width: 48rem) {
        flex-direction: column;
        align-items: center;
      }


      .form-error {
        color: $primary-red;
        font-weight: bold;
        margin: 0;
        font-size: medium;
      }

      .form-group-single {
        flex-direction: row !important;
        display: flex;
        flex-wrap: wrap;

        @media (max-width: 48rem) {
          justify-content: center;
        }
      }

      .form-group {
        display: flex;
        flex-direction: column;
        font-size: large;
        padding: 4px 0;

        label {
          font-weight: bold;
        }

        .form-col {
          margin: 0;
          display: flex;
          justify-content: center;
          flex-direction: column;
          padding: 0 1rem;
        }

        .form-input {
          width: 15rem;
        }

        .form-select {
          width: 15rem;
          padding: 17.5px;
          border: 1px solid rgba(142, 142, 142, 0.5);
          font: inherit;
          border-radius: 4px;
        }
      }
    }

    .fremtur-link {
      text-decoration: none;

      button {
        font-size: larger;
      }
    }


  }
}