@import '../../utilities/variables';

.public-profile-info {
  display: flex;
  margin-top: 16px;
  flex-direction: column;
  background: $tertiary-yellow;
  border: 1px solid $secondary-yellow;
  border-radius: 4px;
  padding: 40px;

  @media (max-width: $phone-width) {
    font-size: small;
    padding: 20px;
  }

  .divider {
    border: 1px solid $secondary-yellow;
    border-radius: 4px;
    margin: 16px 0;
  }


  .public-profile-row {
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;

    &.space-between{
      justify-content: space-between;
    }

    &.hover:hover {
      text-decoration: underline;
    }
  }

  .profile-picture img {
    width: 150px;
    height: 150px;
    border-radius: 10px;

    @media (max-width: $phone-width) {
      width: 120px;
      height: 120px;
    }
  }

}