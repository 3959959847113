@import '../../utilities/variables';

.suggestions {


  .form-group {
    display: flex;
    flex-direction: column;
  }

  .suggestions-list {
    list-style: none;
    padding: 0;
    color: $primary-black;
    border: 1px solid $primary-grey;
    border-radius: 4px;
    cursor: pointer;
    margin: 0.1rem 0.5rem 0 0;
    background-color: $primary-white;
    position: absolute;
    overflow-y: auto;
    max-height: 15rem;
    z-index: 1000;

    .suggestions-list-item {
      display: flex;
      flex-direction: column;
      padding: 15px;
      border-bottom: 1px solid $tertiary-grey;
      margin: 2px;
      border-radius: 4px;
      cursor: pointer;

      &.highlighted {
        background-color: #f0f0f0;
      }


      &:hover {
        background-color: #cccccc;
      }

      .suggestions-county {
        font-size: small;
        color: $secondary-grey;
      }
    }
  }
}
