@import '../../utilities/variables';

.reservation-status {
  display: flex;

  .reservation-status-floating {
    text-align: center;
    padding: .25rem .5rem;
    margin-left: .5rem;
    border-radius: 4px;
    border: 0;
    font: inherit;
    font-size: medium;
    transition: background-color 0.3s ease;

    &.clickable {
      box-shadow: 1.5px 1.5px 5px #999;
      cursor: pointer;

      &:active {
        transform: scale(0.85);
      }

      &:hover {
        background-color: $pending-dark;
      }

    }

    &.pending {
      background-color: $pending;
    }

    &.approved {
      background-color: $approved;
    }

    &.canceled {
      background-color: $cancel;
    }

    &.rejected {
      background-color: $cancel;
    }

    &.cancel {
      background-color: $cancel;

      &:hover {
        background-color: $cancel-dark;
      }
    }
  }
}

.container-expand {
  overflow: hidden;
  font-size: small;

  .trip-expand {
    margin-top: -100%;
    transition: all 1s;

    .trip-driver-info {
      display: flex;
      flex-direction: column;

      .trip-driver-info-row {

        .driver-info-label {
          margin-right: 8px;
        }

        .driver-info-data {
          color: $text-grey
        }
      }
    }

    .trip-divider {
      border: 1px solid $secondary-yellow;
      border-radius: 4px;
      margin: 16px 0;
    }

    .trip-details-row {
      display: flex;
      justify-content: space-between;
    }

    &.expanded {
      margin-top: 0;
    }

    .trip-text {
    }

    .trip-bold {
      font-weight: bold;
    }
  }
}

.trip-expand-btn {
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  background: none;
  border: none;

  .trip-arrow {
    opacity: 50%;
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: .4rem;

    &.up {
      transform: rotate(-135deg);
    }

    &.down {
      transform: rotate(45deg);
    }
  }
}




