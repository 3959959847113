@import '../../utilities/variables';

.user-info {
  background-color: $secondary-yellow;
  padding: 1rem;
  border-radius: .5rem;
  width: $card-width;
  max-width: 100%;

  p {
    margin: 1rem;

    &:last-child {
    }
  }

  i {
    width: 1.5rem;
  }
}