@import '../../utilities/variables';

.driver-info-card {
  background: $tertiary-yellow;
  border: 1px solid $secondary-yellow;
  padding: 15px;
  border-radius: 4px;
  max-width: 90%;
  width: $card-width;

  @media (max-width: $phone-width) {
    width: $card-width-phone;
  }

  .driver-info-row {
    padding: 5px;

    &.space-between {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .driver-info-icon {
      margin-right: 10px;
      color: $secondary-grey;
    }

    .driver-info-label {
      color: $secondary-grey;
      margin-right: 5px;
    }

    .driver-info-data {
      font-weight: bold;
    }

  }
}

