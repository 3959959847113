@import '../../utilities/variables';

.trip-driver-info {
  font-size: small;
  display: flex;
  flex-direction: column;

  .trip-driver-info-row {

    .driver-info-label {
      margin-right: 8px;
    }

    .driver-info-data {
      color: $text-grey
    }
  }
}
