@import '../../utilities/variables';

.trip-list {
  display: flex;
  flex-direction: column;
  width: $card-width;
  max-width: 90%;

  .trip-card {
    color: $primary-black;
    text-decoration: none;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
    padding: 1rem;
    background-color: $tertiary-yellow;
    border-radius: 0.5rem;
    border: 1px solid $secondary-yellow;
    margin-bottom: 1rem;

    &.trip-full {
      opacity: 0.5;
      pointer-events: none;
    }

    .trip-row {
      display: flex;

      &:first-child {
        justify-content: space-between;
      }
      &.driver{
        justify-content: space-between;
      }

      .trip-date {
        font-size: small;
      }

      .trip-cost {
        font-size: small;
        font-weight: bold;
      }

      .trip-col {
        display: flex;
        flex-direction: column;
        margin-right: 10px;

        .trip-time {
          font-size: medium;
        }

        &.available-seats{
          display: flex;
          flex-direction: row;
          justify-content: end;
          font-size: small;
          width: 100%;
          margin-right: 0;
        }

        .trip-timeline {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          margin-bottom: 16px;
        }

        .circle {
          width: 10px;
          height: 10px;
          background-color: $primary-black;
          border-radius: 50%;

          &:first-child {
            margin-top: 4px;
          }
        }

        .line {
          width: 2px;
          height: 53px;
          background-color: $primary-black;
        }

        .trip-segment {
          display: flex;
          align-items: flex-start;

          &:first-child {
            margin-bottom: 8px;
          }

          .trip-location {
            flex-grow: 1;

            .trip-location-name {
              font-size: 16px;
              font-weight: bold;
              color: #333;
            }

            .trip-location-county {
              font-size: 14px;
              color: $text-grey;
            }

            .trip-distance {
              font-size: 12px;
              color: $warning;
              display: flex;
              align-items: center;
              margin-top: 4px;

              .trip-distance-icon {
                margin-right: 4px;
              }
            }
          }
        }

        .trip-driver {
          font-size: small;

          .trip-driver-icon {
            margin-right: 4px;
          }

          .trip-driver-name {
            margin-right: 4px;
          }

          .trip-driver-rating {
            background-color: $secondary-yellow;
            padding: 4px;
            border-radius: 4px;
            font-weight: bold;
          }
        }
      }
    }
  }
}
