@import '../../utilities/variables';

.slider-container {
  color: $text-grey;

  .slider-info {
    display: flex;
    justify-content: center;
    padding-top: 0.5rem;
  }

  .slider-input {
    padding: 0 0.5rem;

    .slider {
      width: 100%;
      -webkit-appearance: none;
      -moz-appearance: none;
      background: $text-grey;

      &.slider-search-page {
        background: $primary-black
      }

      height: 1px;
      border-radius: 5px;
      outline: none;

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        background: $primary-black;
        border-radius: 50%;
        height: 15px;
        width: 15px;
        cursor: pointer;
        transition: .3s ease-in-out;
        appearance: none;
      }

      &::-moz-range-thumb {
        background: $primary-black;
        border-radius: 50%;
        height: 15px;
        width: 15px;
        cursor: pointer;
        transition: .3s ease-in-out;
        appearance: none;
      }
    }
  }
}

