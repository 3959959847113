@import '../../utilities/variables';

.map-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
  margin: 1rem;

  &.show {
    max-height: 38rem;
    opacity: 1;

    &.trip-details {
      max-height: 33rem;
    }
  }

  .map {
    width: 100%;
    height: 23rem;

    &.trip-details {
      height: 18rem;
    }
  }

  .map-info {
    width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-top: 8px;

    .map-distance, .map-tolls, .map-ferry {
      font-size: large;
    }

    .map-toggle {
      opacity: 0.5;
      border: none;
      background-color: inherit;
    }

  }

  .leaflet-pane {
    position: absolute;
  }

  .leaflet-control-container .leaflet-routing-container-hide {
    display: none;
  }

  .leaflet-control-attribution {
    display: none;
  }
}