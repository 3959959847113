@import '../../utilities/variables';

header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  background-color: $primary-yellow;
  z-index: $z-index-header;

  @media (max-width: $phone-width) {
    flex-direction: column;
  }

  .header-bar {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    margin-left: 2rem;

    @media (max-width: $phone-width) {
      margin: 0;
    }

    .header-logo {
      display: flex;
      align-items: center;
      font-family: "Lora Variable", system-ui, sans-serif;
      letter-spacing: 0.1rem;
      font-size: 325%;
      line-height: 1.28;
      font-weight: 700;

      .logo {
        text-decoration: none;
        color: $primary-black;
      }

      @media (max-width: $pad-width) {
        font-size: 175%;
      }
    }
  }

  .header-navigation {
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 1rem 1rem;
    margin-right: 2rem;

    @media (max-width: $phone-width) {
      gap: 0;
      padding: 0;
      margin: 0;
      display: none;
      flex-direction: column;
      align-items: inherit;

      &.toggled {
        display: flex;
      }
    }

    .header-nav-item {
      font-size: larger;
      text-decoration: none;
      color: $primary-black;
      @media (max-width: $pad-width) {
        font-size: medium;
      }

      @media (max-width: $phone-width) {
        font-size: large;
        margin-left: 0;
        padding: 1rem;

        background-color: $tertiary-yellow;
        &:active {
          background-color: $secondary-yellow;
        }
      }
    }

    .header-nav-item:hover {
      font-weight: bolder;
    }
  }
}

.hamburger {
  cursor: pointer;

  @media (min-width: $phone-width) {
    display: none;
  }

  .hamburger-bar {
    width: $bar-width;
    transition: .5s cubic-bezier(0, 0, 0, 1);
    background: black;
    height: $bar-height;
    border-radius: 4px;
    margin: 6px;

    &.top {
      top: 10px;

      &.toggled {
        transform: rotate(45deg) translate(4px, 10px);
      }
    }

    &.middle {
      top: 20px;
      opacity: 1;

      &.toggled {
        opacity: 0;
      }
    }

    &.bottom {
      top: 30px;

      &.toggled {
        &.toggled {
          transform: rotate(-45deg) translate(4px, -10px);
        }
      }
    }
  }
}
